/**
 * Footer bar
 */
import React from "react";
import {Grid, Typography, useMediaQuery, Link as ExternalLink} from "@material-ui/core";
import {useTranslation, Trans} from "react-i18next";
import {useGlobal} from "up-form";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {ReactComponent as Logo} from "./up-logo.svg";
import {Link} from "up-form";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    borderTop: `solid 1px ${theme.palette.divider}`,
    paddingTop: "1em"
  },
  copyright: {
    fontSize: "9px"
  },
  logo: {
    fill: theme.palette.text.secondary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    verticalAlign: "middle"
  },
  needHelp: {
    textAlign: "right",
    "& a": {
      textDecoration: "none",
      color: "inherit"
    }
  },
  links: {
    lineHeight: "2em"
  }
}));

export default function Footer(props) {
  const {t} = useTranslation(),
    classes = useStyles(),
    {
      isDev,
      buildId,
      links: {homeUrl, privacy, cookie, terms}
    } = useGlobal(),
    theme = useTheme(),
    isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Typography className={classes.root} variant="body2" component="footer">
      <Grid container justifyContent="space-between" alignContent="center" alignItems="flex-start" spacing={2}>
        <Grid
          item
          container
          className={classes.links}
          xs={6}
          justifyContent="space-between"
          direction={isMobile ? "column" : "row"}
        >
          <ExternalLink target="_blank" rel="noopener" href={privacy} color="primary">
            {t("Footer.links.privacy")}
          </ExternalLink>
          <ExternalLink target="_blank" rel="noopener" href={cookie} color="primary">
            {t("Footer.links.cookie")}
          </ExternalLink>
          <ExternalLink target="_blank" rel="noopener" href={terms} color="primary">
            {t("Footer.links.terms")}
          </ExternalLink>
          <Link target="_blank" rel="noopener" to="/sitemap.xml" color="primary">
            {t("Footer.links.sitemap")}
          </Link>
        </Grid>
        <Grid
          item
          container
          xs={6}
          className={classes.needHelp}
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "flex-end"}
          justifyContent="flex-end"
          spacing={isMobile ? 0 : 1}
        >
          <Grid item>
            <Typography style={{fontWeight: "bold"}} variant="body1">
              {t("Footer.needHelp.question")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey="Footer.needHelp.answer"
                components={{
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  phone: <a href={`tel:${t("Footer.needHelp.phoneNumber")}`} />
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.copyright} variant="body1">
            <Trans i18nKey="Footer.copyright" />
          </Typography>
        </Grid>
        <Grid item xs={2} style={{textAlign: "right"}}>
          <ExternalLink target="_blank" rel="noopener" href={homeUrl} color="textSecondary">
            <Logo className={classes.logo} />
          </ExternalLink>
        </Grid>
      </Grid>
      {isDev && (
        <Grid container spacing={2} justifyContent="flex-end" style={{fontSize: "xx-small"}}>
          <Grid item>
            <Link to="/edit/config" color="textPrimary">
              Dev Config
            </Link>
          </Grid>
          <Grid item>
            <ExternalLink target="_blank" rel="noopener" href="/changelog.txt">
              Build: {buildId}
            </ExternalLink>
          </Grid>
        </Grid>
      )}
    </Typography>
  );
}
