import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {CurrentEmploymentStatus, BaseSection, IndustryDivision, Occupation, fromId, toId} from "up-form";
import {Optional} from "up-form";
import {isUndefined, get} from "lodash";

const Employment = ({section, form}) => {
  const {t} = useTranslation();
  const prefix = "Employment";
  const selector = formValueSelector(form);

  const {label: currentEmploymentStatus} = useSelector((state) => selector(state, section + ".currentEmploymentStatus") || {});
  const showEmploymentDetails = currentEmploymentStatus && !/(Not employed)|(Unemployed)/.test(currentEmploymentStatus);

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12}>
        <CurrentEmploymentStatus
          name="currentEmploymentStatus"
          label={t(`${prefix}.currentEmploymentStatus.label`)}
          required
          fullWidth
        />
      </Grid>
      {showEmploymentDetails && (
        <>
          <Optional name={`${prefix}.occupation`}>
            <Grid item xs={12}>
              <Occupation name="occupation" label={t(`${prefix}.occupation.label`)} helperText={t(`${prefix}.occupation.text`)} />
            </Grid>
          </Optional>
          <Optional name={`${prefix}.industry`}>
            <Grid item xs={12}>
              <IndustryDivision name="industry" label={t(`${prefix}.industry.label`)} helperText={t(`${prefix}.industry.text`)} />
            </Grid>
          </Optional>
        </>
      )}
    </BaseSection>
  );
};

Employment.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Employment;

export function mapToApplication({currentEmploymentStatus, industry, occupation}) {
  return {
    history: {
      industryDivisionId: toId(industry),
      occupationId: toId(occupation)
    },
    opportunity: {
      currentEmploymentStatusId: toId(currentEmploymentStatus)
    }
  };
}

export function mapFromApplication(
  {history: {occupationId, industryDivisionId} = {}, opportunity: {currentEmploymentStatusId} = {}},
  {metadata: {currentEmploymentStatuses, industryDivisions, occupations}}
) {
  return {
    currentEmploymentStatus: fromId(currentEmploymentStatuses, currentEmploymentStatusId),
    industry: fromId(industryDivisions, industryDivisionId),
    occupation: fromId(occupations, occupationId)
  };
}

export function isComplete(application, excluded) {
  // We can only call the section complete if any of the mandatory fields have been set, otherwise we can't just skip the section
  return application && ["opportunity.currentEmploymentStatusId"].findIndex((field) => !isUndefined(get(application, field))) >= 0;
}
