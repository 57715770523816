/**
 * Form for Summary section
 */
import PropType from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import {acceptance} from "redux-form-validators";
import {BaseSection, Checkbox, fromId, Optional, toCourseIntake, toLabel, Typography, useGlobal} from "up-form";
import {useApplication, useCourses, useMetadata} from "up-state";
import {Grid, Link} from "@material-ui/core";
import moment from "moment";

const Declaration = ({form, section}) => {
  const {t} = useTranslation();
  const {links, providerSlug} = useGlobal();
  const replacements = {
    // Supply all links for use in translations
    ...Object.entries(links)
      .map(([name, ref]) => [name, <Link underline="always" target="_blank" href={ref} />])
      .reduce((acc, [key, comp]) => ({[key]: comp, ...acc}), {}),
    declareCheck: <Checkbox color="primary" name="agreeDeclaration" required validate={acceptance()} />,
    termsCheck: <Checkbox color="primary" name="agreeTerms" required validate={acceptance()} />,
    youthGuaranteeCheck: <Checkbox color="primary" name="youthGuaranteeSigned" required validate={acceptance()} />
  };

  // urrrgg all this just to remove the handbook link if its not a Microcredential course on the yoobee SC provider
  const {data: courses} = useCourses(providerSlug);
  const {data: {courseClassifications} = {}} = useMetadata();
  const {
    data: {opportunity: {intakeCRMId} = {}, declaration: {youthGuaranteeRequired} = {}}
  } = useApplication();
  const {course} = (courses && intakeCRMId && toCourseIntake({intakeCRMId}, courses)) || {};
  const {courseClassificationCode} = course || {};
  const courseClassification =
    courseClassificationCode && courseClassificationCode && toLabel(fromId(courseClassifications, courseClassificationCode));
  const yoobeeShortCourse = providerSlug === "yoobee-short-courses" && !/Micro-credential/i.test(courseClassification);

  return (
    <BaseSection section={section} title={t("Declaration.title")}>
      {["acceptance", "payment", yoobeeShortCourse ? "shortCourseTerms" : "terms", "declaration"]
        .map((sub) => `Declaration.${sub}`)
        .map((key, i) => (
          <Optional name={key}>
            <Grid key={i} item xs={12}>
              <Typography variant="h6">{t(`${key}.title`)}</Typography>
              <Typography gutterBottom variant="body1" component="div">
                <Trans t={t} i18nKey={`${key}.text`} components={replacements} />
              </Typography>
            </Grid>
          </Optional>
        ))}
      {youthGuaranteeRequired && (
        <Grid item xs={12}>
          <Typography variant="h6">{t("Declaration.youthGuarantee.title")}</Typography>
          <Typography gutterBottom variant="body1" component="div">
            <Trans t={t} i18nKey={"Declaration.youthGuarantee.text"} components={replacements} />
          </Typography>
        </Grid>
      )}
    </BaseSection>
  );
};

export default Declaration;

Declaration.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({agreeTerms, agreeDeclaration, youthGuaranteeSigned}) {
  return {
    declaration: {
      agreeTerms,
      agreeDeclaration,
      youthGuaranteeSigned: youthGuaranteeSigned,
      youthGuaranteeSignedDate: (youthGuaranteeSigned && moment().format("YYYY-MM-DD")) || undefined
    }
  };
}
export function mapFromApplication({
  declaration: {agreeTerms, agreeDeclaration, youthGuaranteeRequired, youthGuaranteeSigned} = {}
}) {
  return {
    agreeTerms,
    agreeDeclaration,
    youthGuaranteeRequired,
    youthGuaranteeSigned
  };
}
export function isComplete(application, excluded) {
  const {declaration: {agreeTerms, agreeDeclaration, youthGuaranteeRequired, youthGuaranteeSigned} = {}} = application || {};
  return (excluded.has("Declaration.terms") || agreeTerms) && agreeDeclaration && (!youthGuaranteeRequired || youthGuaranteeSigned);
}