import {Grid} from "@material-ui/core";
import PropType from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, isYes, TextField, toYesNo, YesNo} from "up-form";
import {Optional} from "up-form";

const History = ({form, section}) => {
  const {t} = useTranslation(),
    selector = formValueSelector(form),
    criminalConvictions = useSelector((state) => selector(state, section + ".criminalConvictions"));

  // N.b currently the only item collected in history is criminalConvictions, which is optional, surround whole section
  return (
    <Optional name="History.criminalConvictions">
      <BaseSection section={section} title={t("History.title")}>
        <Grid item xs={12} sm={6}>
          <YesNo required label={t("History.criminalConvictions.label")} name="criminalConvictions" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            disabled={criminalConvictions !== "yes"}
            rows={5}
            label={t("History.criminalConvictionsDescription.label")}
            name="criminalConvictionsDescription"
          />
        </Grid>
      </BaseSection>
    </Optional>
  );
};

export default History;

History.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({criminalConvictionsDescription, criminalConvictions}) {
  return {
    history: {
      hasCriminalConvictions: isYes(criminalConvictions),
      convictionDetails: criminalConvictionsDescription
    }
  };
}
export function mapFromApplication({history: {hasCriminalConvictions, convictionDetails} = {}}) {
  return {
    criminalConvictions: toYesNo(hasCriminalConvictions),
    criminalConvictionsDescription: convictionDetails
  };
}
