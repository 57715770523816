import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {
	AboriginalTSIOrigin,
	BaseSection,
	CitizenshipStatus,
	Country,
	fromId,
	isYes,
	toId,
	toYesNo
} from "up-form";

const ResidencyStatusAU = ({section, form}) => {
	const {t} = useTranslation();
	const prefix = "ResidencyStatusAU";

	return (
		<BaseSection section={section} title={t(`${prefix}.title`)}>
			<Grid item xs={12}>
				<Country
					label={t(`${prefix}.countryOfBirth.label`)}
					name="countryOfBirth"
					fullWidth
					required
				/>
			</Grid>
			<Grid item xs={12}>
				<AboriginalTSIOrigin
					label={t(`${prefix}.indigenousIdentifier.label`)}
					name="indigenousIdentifier"
					required
				/>
			</Grid>
			<Grid item xs={12}>
				<CitizenshipStatus
					label={t(`${prefix}.citizenshipStatus.label`)}
					name="citizenshipStatus"
					required
				/>
			</Grid>
			<Grid item xs={12}>
				<Country
					label={t(`${prefix}.countryOfCitizenship.label`)}
					name="countryOfCitizenship"
					fullWidth
					required
				/>
			</Grid>
		</BaseSection>
	);
};

ResidencyStatusAU.propTypes = {
	form: PropTypes.string.isRequired,
	section: PropTypes.string.isRequired
};

export default ResidencyStatusAU;

export function mapToApplication(
	{
		indigenousIdentifier,
		ausResident,
		citizenshipStatus,
		countryOfBirth,
		countryOfCitizenship
	},
	{metadata: {languages}}
) {
	return {
		residency: {
			australianResident: isYes(ausResident),
			countryOfBirthId: toId(countryOfBirth),
			indigenousIdentifierId: toId(indigenousIdentifier),
			citizenshipStatusId: toId(citizenshipStatus),
			countryOfCitizenshipId: toId(countryOfCitizenship)
		}
	};
}

export function mapFromApplication(
	{
		residency: {
			australianResident,
			countryOfBirthId,
			countryOfCitizenshipId,
			citizenshipStatusId,
			indigenousIdentifierId
		} = {}
	},
	{metadata: {aboriginalTSIOrigins, citizenshipStatuses, countries}}
) {
	//const languageSpokenAtHome = languageSpokenAtHomeId ? fromId(languages, languageSpokenAtHomeId) : fromLabel(languages,"English");
	return {
		ausResident: toYesNo(australianResident),
		countryOfBirth: fromId(countries, countryOfBirthId),
		countryOfCitizenship: fromId(countries, countryOfCitizenshipId),
		citizenshipStatus: fromId(citizenshipStatuses, citizenshipStatusId),
		indigenousIdentifier: fromId(aboriginalTSIOrigins, indigenousIdentifierId)
	};
}
