/**
 * Form for Declaration step
 */
import {Box, Grid, Paper, withStyles} from "@material-ui/core";
import VerifiedUserTwoToneIcon from "@material-ui/icons/VerifiedUserTwoTone";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {Typography} from "up-form";
const Verification = withStyles(
  (theme) => ({
    bar: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.dark),
      fontSize: "small",
      position: "fixed",
      overflow: "visible",
      width: "100%",
      left: 0
    },
    thumb: {
      verticalAlign: "text-top",
      marginLeft: ".5em"
    },
    paragraph: {},
    request: {
      fontSize: "large",
      fontWeight: "600"
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "4rem",
      width: "auto",
      height: "auto"
    },
    email: {
      color: theme.palette.primary.main
    }
  }),
  {name: "UpVerification"}
)(({form, classes}) => {
  const {t} = useTranslation(),
    selector = formValueSelector(form),
    {firstName, lastName, emailAddress} = useSelector((state) => selector(state, "details") || {});

  return (
    <>
      <Box paddingTop={5} marginBottom={5}>
        <Paper elevation={1}>
          <Box padding={3} marginBottom={3}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <VerifiedUserTwoToneIcon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h3" gutterBottom>
                  {t(`Verification.request.${firstName && lastName && emailAddress ? "title" : "titleAnonymous"}`, {
                    firstName,
                    emailAddress,
                    lastName
                  })}
                </Typography>
                <Typography variant="body1" gutterBottom color="primary" className={classes.request} component="div">
                  <Trans i18nKey={`Verification.request.text`} />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {["needHelp"].map((key, i) => (
          <React.Fragment key={i}>
            <Typography variant="h5" gutterBottom>
              {t(`Verification.${key}.title`, {name: firstName})}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.paragraph} component="div">
              <Trans i18nKey={`Complete.${key}.text`} />
            </Typography>
          </React.Fragment>
        ))}
      </Box>
    </>
  );
});

export default Verification;
