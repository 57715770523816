import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {ApiBusy, Typography} from "up-form";
import {useGlobal} from "up-form";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: ({logoBg}) => ({
    //marginBottom: '1em',
    flexGrow: 1,
    backgroundColor: logoBg || theme.palette.background.default
  }),
  colorPrimary: ({logoBg}) => ({
    color: theme.palette.getContrastText(logoBg || theme.palette.background.default)
  }),
  title: {
    flexGrow: 1,
    textTransform: "none"
  },
  logo: ({logoBg}) => ({
    maxWidth: "50%",
    maxHeight: "108px",
    padding: "1em",
    backgroundColor: logoBg
  })
}));

const DomesticAppBar = React.forwardRef((props, ref) => {
  const {logo, logoBg, providerSlug} = useGlobal();
  const classes = useStyles({logoBg});
  const {t} = useTranslation();
  return (
    <AppBar
      color="primary"
      classes={{root: classes.root, colorPrimary: classes.colorPrimary}}
      position="sticky"
      elevation={1}
      ref={ref}
    >
      <ApiBusy />
      <Toolbar>
        {logo && <img className={classes.logo} src={logo} alt={providerSlug} />}
        <Typography variant="h6" align="right" className={classes.title}>
          {t("App.fullName")}
        </Typography>
      </Toolbar>
    </AppBar>
  );
});

export default DomesticAppBar;
