import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useGlobal} from "up-form";

const Head = () => {
	const {favicon} = useGlobal();
	const {t} = useTranslation();
	return (
		<Helmet>
			<title>
				{t("Provider.name")} - {t("EnrolmentForm.title.label")}
			</title>
			{favicon && <link rel="icon" href={favicon} />}
		</Helmet>
	);
};

export default Head;
