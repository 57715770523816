import {Grid} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {TextField} from "up-form";

function PayLater({i18nPrefix, prefix}) {
	const {t} = useTranslation();
	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<TextField
					fullWidth
					required
					name={`${prefix}.companyName`}
					label={t(`${i18nPrefix}.companyName.label`)}
				/>
			</Grid>
			<Grid item>
				<TextField
					fullWidth
					type="email"
					required
					name={`${prefix}.companyEmail`}
					label={t(`${i18nPrefix}.companyEmail.label`)}
				/>
			</Grid>
			<Grid item>
				<TextField
					fullWidth
					required
					multiline
					rows={5}
					name={`${prefix}.companyAddress`}
					label={t(`${i18nPrefix}.companyAddress.label`)}
				/>
			</Grid>
			<Grid item>
				<TextField
					fullWidth
					type="phone"
					required
					name={`${prefix}.companyPhone`}
					label={t(`${i18nPrefix}.companyPhone.label`)}
				/>
			</Grid>
			<Grid item>
				<TextField
					fullWidth
					name={`${prefix}.purchaseOrderNumber`}
					label={t(`${i18nPrefix}.purchaseOrderNumber.label`)}
				/>
			</Grid>
		</Grid>
	);
}

export default PayLater;
