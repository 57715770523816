import {Grid} from "@material-ui/core";
import PropType from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, fromLabel, isYes, TextField, toId, toYesNo, YesNo} from "up-form";
import {Optional} from "up-form";

const Health = ({form, section}) => {
  const {t} = useTranslation();
  const selector = formValueSelector(form);
  const otherMedicalDetails = useSelector((state) => selector(state, section + ".otherMedicalDetails"));
  const disability = useSelector((state) => selector(state, section + ".disability"));
  return (
    <BaseSection section={section} title={t("Health.title")}>
      <Optional name="Health.disability">
        <Grid item xs={12} sm={6}>
          <YesNo required label={<>{t("Health.disability.label")}</>} name="disability" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            disabled={disability !== "yes"}
            rows={5}
            label={t("Health.disabilityDescription.label")}
            name="disabilityDescription"
          />
        </Grid>
      </Optional>
      <Optional name="Health.otherMedicalDetails">
        <Grid item xs={12} sm={6}>
          <YesNo required label={t("Health.otherMedicalDetails.label")} name="otherMedicalDetails" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            disabled={otherMedicalDetails !== "yes"}
            rows={5}
            label={t("Health.otherMedicalDetailsDescription.label")}
            name="otherMedicalDetailsDescription"
          />
        </Grid>
      </Optional>
      <Optional name="Health.medicalConditionsAndMedication">
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={5}
            label={t("Health.medicalConditionsAndMedication.label")}
            name="medicalConditionsAndMedication"
          />
        </Grid>
      </Optional>
      <Optional name="Health.assistanceRequiredInEmergency">
        <Grid item xs={12} sm={6}>
          <YesNo required label={<>{t("Health.assistanceRequiredInEmergency.label")}</>} name="assistanceRequiredInEmergency" />
        </Grid>
      </Optional>
    </BaseSection>
  );
};

export default Health;

Health.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication(
  {
    assistanceRequiredInEmergency,
    disability,
    disabilityDescription,
    medicalConditionsAndMedication,
    otherMedicalDetails,
    otherMedicalDetailsDescription
  },
  {metadata: {disabilityStatuses}}
) {
  return {
    health: {
      assistanceRequiredInEmergency: isYes(assistanceRequiredInEmergency),
      disability: isYes(disability),
      disabilityId: toId(
        fromLabel(disabilityStatuses, isYes(disability) ? "Disability Identified" : "No Disability was Identified")
      ),
      disabilityDescription: isYes(disability) ? disabilityDescription : undefined,
      medicalConditionsAndMedication,
      priorHealthConditionDeclared: isYes(otherMedicalDetails),
      otherMedicalDetails: isYes(otherMedicalDetails) ? otherMedicalDetailsDescription : undefined
    }
  };
}
export function mapFromApplication({
  health: {
    assistanceRequiredInEmergency,
    disability,
    disabilityDescription,
    medicalConditionsAndMedication,
    otherMedicalDetails,
    priorHealthConditionDeclared
  } = {}
}) {
  return {
    assistanceRequiredInEmergency: toYesNo(assistanceRequiredInEmergency),
    disability: toYesNo(disability),
    disabilityDescription: disabilityDescription,
    medicalConditionsAndMedication,
    otherMedicalDetails: toYesNo(priorHealthConditionDeclared),
    otherMedicalDetailsDescription: otherMedicalDetails
  };
}
