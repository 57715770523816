import {Box, CircularProgress, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {useGlobal} from "up-form";
import {useApplication} from "up-state";
import {Enrol} from "./Enrol";

/**
 * Component loads current application data from saved session ad redirects to Enrol page
 */
export const ResumeEnrol = ({resumeStep: requestedStep, applicationUpdate, sessionId: sessionIdProp, children, ...other}) => {
  const {providerSlug} = useGlobal();
  const {hash} = useLocation();
  const resumeStep = requestedStep || (hash && hash.slice(1));

  const {sessionId} = useParams();
  const {
    error: applicationError,
    pending,
    data: application
  } = useApplication({
    sessionId,
    update: applicationUpdate
  });
  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    if (application) setInitialised(true);
  }, [application]);

  return (
    <>
      {pending && !applicationError && !initialised && (
        <Box padding={5}>
          <CircularProgress />
          <Typography variant="h5">Resuming session...</Typography>
        </Box>
      )}

      {
        // Success redirect
        initialised && (
          <Enrol defaultStep={resumeStep} {...other}>
            {children}
          </Enrol>
        )
      }
      {applicationError && !initialised && (
        // Enrol page handles error after initialise
        // Failed to reload application form
        <Redirect
          to={`/${providerSlug}/error/${applicationError.status}?message=${applicationError.message}`}
        />
      )}
    </>
  );
};
