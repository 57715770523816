import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Warning from "@material-ui/icons/Warning";
import {SvgIcon, withStyles} from "@material-ui/core";
export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: "block",
    fill: "transparent",
    stroke: theme.palette.text.disabled,
    strokeLineCap: "round",
    strokeLineJoin: "round",
    "&$completed": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main
    },
    "&$active": {
      stroke: theme.palette.primary.main
    },
    "&$error": {
      stroke: theme.palette.error.main
    }
  },

  edit: {
    stroke: theme.palette.background.paper,
    fill: theme.palette.primary.main
  },

  /* Styles applied to the SVG text element. */
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.fontFamily
  },
  /* Pseudo-class applied to the root element if `active={true}`. */
  active: {},
  /* Pseudo-class applied to the root element if `completed={true}`. */
  completed: {},
  /* Pseudo-class applied to the root element if `error={true}`. */
  error: {}
});

const StepIcon = React.forwardRef(function StepIcon(props, ref) {
  const {completed = false, icon, active = false, error = false, classes, edit = false} = props;

  if (typeof icon === "number" || typeof icon === "string") {
    const className = clsx(classes.root, {
      [classes.active]: active,
      [classes.error]: error,
      [classes.completed]: completed
    });

    if (error) {
      return <Warning className={className} ref={ref} />;
    }

    if (completed) {
      return <CheckCircleOutline className={className} ref={ref} />;
    }

    if (edit) {
      return (
        <SvgIcon className={classes.edit} ref={ref}>
          <ellipse cx="12" cy="12" rx="11" ry="11" />
          <path d="m15 6a1 1 0 0 1 2 2l-9 9-3 0.7 0.7-3z" />
          <path d="m11 17h6" />
        </SvgIcon>
      );
    }

    return (
      <SvgIcon className={className} ref={ref}>
        <circle cx="12" cy="12" r="10" strokeWidth="2px" />
        <text className={classes.text} x="12" y="16" textAnchor="middle">
          {icon}
        </text>
      </SvgIcon>
    );
  }

  return icon;
});

StepIcon.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * Mark the step as failed.
   */
  error: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
};

export default withStyles(styles, {name: "MuiStepIcon"})(StepIcon);
