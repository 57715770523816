import {red, grey, green, orange, lightBlue} from "@material-ui/core/colors";
import common from "@material-ui/core/colors/common";
import {createTheme} from "@material-ui/core/styles";
import {responsiveFontSizes} from "@material-ui/core";
import {merge} from "lodash";
import {lighten, darken} from "@material-ui/core/styles/colorManipulator";

const {black, white} = common;
const defaultPalette = {
  success: green,
  warning: orange,
  info: lightBlue
};

// A root theme shared by all child pages
const rootThemeData = {
  // spacing: 16,
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    useNextVariants: true,
    h1: {fontSize: "32px", fontWeight: 800},
    h2: {fontSize: "24px", fontWeight: 800},
    h3: {fontSize: "20px", fontWeight: 600},
    h4: {fontSize: "16px", fontWeight: 700},
    h5: {fontSize: "14px", fontWeight: 700},
    h6: {fontSize: "12px", fontWeight: 700}
  },
  palette: {
    primary: {
      main: "#006A90"
    },
    secondary: {
      main: "#555"
    },
    ...defaultPalette
  },
  status: {
    danger: "red"
  },

  overrides: {
    MuiLink: {
      root: {
        whiteSpace: "nowrap"
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: "1.25em",
        fontWeight: "bold"
      }
    },
    MuiButton: {
      root: {
        fontWeight: "600",
        borderRadius: "2em",
        lineHeight: "2.75em"
      }
    },
    MuiToggleButton: {
      root: {
        "&.Mui-disabled": {
          opacity: "50%"
        }
      }
    },
    MuiTypography: {
      root: {
        "& dt": {
          flexBasis: "20%",
          textAlign: "left",
          padding: "2px 4px",
          fontWeight: "bold"
        },
        "& dd": {
          flexBasis: "70%",
          flexGrow: 1,
          margin: 0,
          padding: "2px 4px"
        },
        "& dl": {
          display: "flex",
          flexFlow: "row wrap"
        }
      }
    },
    UpFieldSet: {
      legend: {
        fontWeight: "bold"
      }
    },
    UpSectionHeading: {
      heading: {
        textTransform: "none"
      }
    }
  }
};

const themeByProvider = {
  up: {
    palette: {
      ...defaultPalette,
      primary: {
        main: "#006990"
      },
      secondary: {
        main: "#006990"
      }
    }
  },
  hanz: {
    typography: {
      fontFamily: "UntitledSans"
    },
    palette: {
      ...defaultPalette,
      primary: {main: "#4BB28A"},
      secondary: {main: "#ab91ce"},
      background: {
        paper: "#e9e9df",
        default: "#e9e9df"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": {
            fontFamily: "UntitledSans",
            src: 'url("/providers/hanz/UntitledSans-Regular.otf") format("opentype")'
          }
        }
      }
    }
  },
  yoobee: {
    palette: {
      ...defaultPalette,
      primary: {main: "#00B57E"},
      secondary: red,
      success: {
        main: "#b1fb70"
      }
    }
  },
  tcc: {
    palette: {
      ...defaultPalette,
      primary: {main: "#00a650"},
      secondary: {main: white},
      text: {primary: black, secondary: grey[900]},
      background: {
        default: white
      }
    }
  },
  nzcm: {
    palette: {
      ...defaultPalette,
      primary: {main: "#6d8a34"},
      secondary: {main: "#575756"},
      text: {primary: black, secondary: grey[900]},
      background: {
        default: "#252E33"
      }
    }
  },
  nzma: {
    palette: {
      ...defaultPalette,
      primary: {main: "#009FE3"},
      secondary: {main: "#14699E"}
    }
  },
  nzis: {
    palette: {
      ...defaultPalette,
      primary: {main: "#ec8d20"},
      secondary: {main: "#14699E"},
      background: {
        default: "#252E33"
      }
    }
  },
  nzst: {
    palette: {
      ...defaultPalette,
      primary: {main: "#FF29B6"},
      secondary: {main: "#b1b1b1"},
      background: {
        default: black,
        paper: white
      }
    }
  },
  elite: {
    palette: {
      ...defaultPalette,
      type: "dark",
      primary: {main: "#D3B987"},
      secondary: {main: "#14699E"},
      text: {primary: grey["A100"], secondary: grey["A100"]},
      background: {
        default: black,
        paper: "#333333"
      }
    }
  },
  "cut-above": {
    palette: {
      ...defaultPalette,
      type: "dark",
      primary: {main: "#F89307"},
      secondary: {main: white},
      text: {primary: white, secondary: grey[400]},
      background: {
        default: black,
        paper: "#3c3c3b"
      }
    }
  },
  ichm: {
    typography: {
      fontFamily: "GraphickWide"
    },
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {main: "#000070"},
      secondary: {main: "#ED4036"},
      text: {primary: "#000070", secondary: "#A6A6A6"},
      background: {
        default: white,
        paper: white
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": {
            fontFamily: "GraphickWide",
            src: 'url("/providers/ichm/GraphikWide-Regular-Web.woff2") format("woff2")'
          }
        }
      }
    }
  },
  "new-zealand-tertiary-college": {
    palette: {
      ...defaultPalette,
      primary: {main: "#006575"},
      secondary: {main: "#4b1463"},
      background: {
        default: "#000000"
      }
    }
  }
};
const rootTheme = createTheme(rootThemeData);

export {rootTheme};
export function createProviderTheme(provider) {
  const theme = responsiveFontSizes(createTheme(merge({}, rootThemeData, themeByProvider[provider]))),
    {
      palette: {
        type,
        tonalOffset,
        background: {paper},
        primary: {light, main, dark}
      }
    } = theme;
  return merge(theme, {
    overrides: {
      MuiLink: {
        root: {
          color: type === "dark" ? dark : light
        }
      },

      MuiPaper: {
        ...[...Array(10)]
          .map((v, i) => ({
            backgroundColor: type === "dark" ? lighten(paper, (tonalOffset * i) / 8) : darken(paper, (tonalOffset * i) / 8)
          }))
          .reduce((a, v, i) => {
            a[`elevation${i}`] = v;
            return a;
          }, {})
      },
      MuiTypography: {
        root: {
          "& ul": {
            listStyle: "none",
            "& li::before": {
              content: '"\\2022"',
              color: main,
              fontWeight: "bold",
              display: "inline-block",
              width: "1em",
              marginLeft: "-1em",
              verticalAlign: "middle"
            }
          }
        }
      }
    }
  });
}
