import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {BaseSection, fromId, fromLabel, isYes, Language as ChooseLanguage, SpokenEnglish, toId, toYesNo, YesNo} from "up-form";
import {useMetadata} from "up-state";
import {Optional} from "up-form";

const Language = ({section, form}) => {
  const {t} = useTranslation();
  const prefix = "Language";
  const {data: {languages} = {}} = useMetadata();

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Optional name={`${prefix}.languageSpokenAtHome`}>
        <Grid item xs={12} sm={6}>
          <ChooseLanguage
            defaultValue={languages && fromLabel(languages, "English")}
            label={t(`${prefix}.languageSpokenAtHome.label`)}
            name="languageSpokenAtHome"
            helperText={t(`${prefix}.languageSpokenAtHome.help`)}
            fullWidth
          />
        </Grid>
      </Optional>
      <Optional name={`${prefix}.spokenEnglish`}>
        <Grid item xs={12} sm={6}>
          <SpokenEnglish label={t(`${prefix}.spokenEnglish.label`)} name="spokenEnglish" required />
        </Grid>
      </Optional>
      <Optional name={`${prefix}.requiresEnglishHelp`}>
        <Grid item xs={12} sm={6}>
          <YesNo label={t(`${prefix}.requiresEnglishHelp.label`)} name="requiresEnglishHelp" required />
        </Grid>
      </Optional>
      <Optional name={`${prefix}.requiresLanguageLiteracyNumeracyHelp`}>
        <Grid item xs={12} sm={6}>
          <YesNo
            label={t(`${prefix}.requiresLanguageLiteracyNumeracyHelp.label`)}
            name="requiresLanguageLiteracyNumeracyHelp"
            required
          />
        </Grid>
      </Optional>
    </BaseSection>
  );
};

Language.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Language;

export function mapToApplication(
  {languageSpokenAtHome, requiresEnglishHelp, requiresLanguageLiteracyNumeracyHelp, spokenEnglish},
  {metadata: {languages}}
) {
  return {
    language: {
      requiresEnglishHelp: isYes(requiresEnglishHelp),
      requiresLanguageLiteracyNumeracyHelp: isYes(requiresLanguageLiteracyNumeracyHelp),
      languageSpokenAtHomeId: toId(languageSpokenAtHome),
      spokenEnglishId: toId(spokenEnglish)
    }
  };
}

export function mapFromApplication(
  {language: {requiresEnglishHelp, languageSpokenAtHomeId, spokenEnglishId, requiresLanguageLiteracyNumeracyHelp} = {}},
  {metadata: {languages, spokenEnglishLevels}}
) {
  //const languageSpokenAtHome = languageSpokenAtHomeId ? fromId(languages, languageSpokenAtHomeId) : fromLabel(languages,"English");
  return {
    languageSpokenAtHome: fromId(languages, languageSpokenAtHomeId),
    requiresEnglishHelp: toYesNo(requiresEnglishHelp) || undefined,
    requiresLanguageLiteracyNumeracyHelp: toYesNo(requiresLanguageLiteracyNumeracyHelp) || undefined,
    spokenEnglish: fromId(spokenEnglishLevels, spokenEnglishId)
  };
}
