import {Button, Grid, Paper, withStyles} from "@material-ui/core";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {page} from "up-analytics";
import {ErrorDetails, useGlobal, useQuery} from "up-form";
import {useApplication} from "up-state";

const style = (theme) => ({
  accordionRoot: {
    "&::before": {
      backgroundColor: "transparent"
    },
    backgroundColor: "transparent"
  },
  icon: {
    fontSize: "5rem",
    marginRight: ".25rem",
    color: theme.palette.error.main,
    verticalAlign: "middle"
  }
});

const ErrorPage = ({classes}) => {
  const {t} = useTranslation();
  const {status: paramStatus} = useParams();
  const {message, i18nKey, status: queryStatus} = useQuery();
  const {data: {opportunity: {opportunityCRMId, leadCRMId, intakeCRMId} = {}} = {}} = useApplication();
  const status = paramStatus || queryStatus;
  useEffect(() => {
    page("ErrorPage", status, {message});
  }, [status, message]);
  const {providerSlug} = useGlobal();

  const {
    links: {publicUrl}
  } = useGlobal();
  const sessionId = sessionStorage.getItem("up.sessionId");
  const resumeUrl = sessionId && `/${providerSlug}/enrolling/${sessionId}`;
  return (
    <Grid container justifyContent="center" alignItems="center" style={{height: "80vh"}}>
      <Paper variant="outlined">
        <ErrorDetails
          statusCode={status}
          status={i18nKey && t(i18nKey)}
          message={message}
          actions={[
            publicUrl && (
              <Button variant="contained" color="inherit" href={`${publicUrl}/${providerSlug}`}>
                {t("ErrorPage.action.restart")}
              </Button>
            ),
            resumeUrl && (
              <Button variant="contained" color="inherit" href={resumeUrl}>
                {t("ErrorPage.action.resume")}
              </Button>
            )
          ]}
          extra={{opportunityCRMId, leadCRMId, intakeCRMId}}
        />
      </Paper>
    </Grid>
  );
};

export default withStyles(style)(ErrorPage);
