import {Box, Container, Grid, Link, Paper, withStyles} from "@material-ui/core";
import {Typography} from "up-form";
import {startCase} from "lodash";
import React from "react";
import {providers, providerWithGroups} from "../../config";
export const Index = withStyles(
  (theme) => ({
    root: {
      height: "100%",
      backgroundColor: "#eee"
    },
    image: {
      height: "100%",
      width: "100%",
      objectFit: "contain"
    },
    caption: {
      textAlign: "center",
      display: "block",
      margin: ".25em"
    },
    button: {
      backgroundColor: "#ddd",
      width: "8em",
      height: "6em",
      padding: "1em",
      transition: "transform .2s linear",
      "&:hover": {
        transform: "scale(1.1)"
      }
    }
  }),
  {name: "index"}
)(({classes}) => {
  return (
    <Container className={classes.root}>
      <Box padding={3}>
        <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center">
          {Object.entries(providers)
            .map(([slug, providerConfig]) => [slug, providerWithGroups(providerConfig)])
            .map(
              (
                [
                  providerSlug,
                  {
                    global: {logoBg, logo}
                  }
                ],
                i
              ) => (
                <Grid item key={i}>
                  <Link href={`/${providerSlug}`}>
                    <Paper className={classes.button} elevation={5} style={{backgroundColor: logoBg}}>
                      <img className={classes.image} src={`${logo}`} alt={providerSlug} />
                    </Paper>
                    <Typography variant="caption" className={classes.caption}>
                      {startCase(providerSlug.replace(/-/g, " "))}
                    </Typography>
                  </Link>
                </Grid>
              )
            )}
        </Grid>
      </Box>
    </Container>
  );
});
