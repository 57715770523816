import {Grid} from "@material-ui/core";
import PropType from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {BaseSection, fromId, fromPhone, Relationship, TextField, toId, toPhone, TypedPhone} from "up-form";

const EmergencyContact = ({section}) => {
  const {t} = useTranslation();
  // Api requires that names/contact details must differ
  const mustDiffer = (value, {details, emergencyContact}) =>
    details &&
    emergencyContact &&
    [
      [details.firstName, emergencyContact.firstName],
      [details.lastName, emergencyContact.lastName],
      [details.emailAddress, emergencyContact.email]
    ].every((v) => (v[0] || "").trim().toLowerCase() === (v[1] || "").trim().toLowerCase()) &&
    t("EmergencyContact.error.mustDiffer");

  return (
    <BaseSection section={section} title={t("EmergencyContact.title")}>
      <Grid item xs={12} sm={6}>
        <TextField required fullWidth label={t("EmergencyContact.firstName.label")} name="firstName" validate={mustDiffer} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField required fullWidth label={t("EmergencyContact.lastName.label")} name="lastName" validate={mustDiffer} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Relationship fullWidth label={t("EmergencyContact.relationship.label")} name="relationship" />
      </Grid>
      <Grid item xs={12}>
        <TypedPhone fullWidth label={t("EmergencyContact.phone.label")} required />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth label={t("EmergencyContact.email.label")} type="email" name="email" validate={mustDiffer} />
      </Grid>
    </BaseSection>
  );
};

export default EmergencyContact;

EmergencyContact.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication(emergencyContact) {
  const {firstName, lastName, email, relationship} = emergencyContact || {};
  return {
    contacts: {
      emergency: {
        firstName,
        lastName,
        emailAddress: email,
        relationshipToStudentId: toId(relationship),
        ...toPhone(emergencyContact)
      }
    }
  };
}
export function mapFromApplication({contacts: {emergency = {}} = {}}, {metadata: {relationships}}) {
  const {firstName, lastName, email, relationshipToStudentId} = emergency;
  return {
    firstName,
    lastName,
    email,
    relationship: fromId(relationships, relationshipToStudentId),
    ...fromPhone(emergency)
  };
}
