import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { styles as notchedOutlineStyles } from "@material-ui/core/OutlinedInput/NotchedOutline";
import { styles as inputBaseStyles } from "@material-ui/core/InputBase/InputBase";
import { styles as formLabelStyles } from "@material-ui/core/FormLabel/FormLabel"
const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...inputBaseStyles(theme).root,
      margin: 0,
      padding: "0 8px",
      borderRadius: "4px",
      borderStyle: "solid",
      borderWidth: 1,
      display: 'block'
    },
     legend: {
        ...formLabelStyles(theme).root,
        ...notchedOutlineStyles(theme).legendLabelled,
        ...notchedOutlineStyles(theme).legendNotched,
      visibility: 'visible'
    },
    box: {},
    grid: {}
  }),
  { name: "UpFieldSet" }
);
const FieldSet = ({ label, children, direction }) => {
  const classes = useStyles();
  return (
    <fieldset className={classes.root}>
      <legend className={classes.legend}>{label}</legend>
      <Box className={classes.box} padding={2}>
        <Grid className={classes.grid} container spacing={2} {...{ direction }}>
          {children}
        </Grid>
      </Box>
    </fieldset>
  );
};

export default FieldSet;
