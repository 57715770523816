import React from 'react';
//import InfoOutlined from '@material-ui/icons/InfoOutlined';
//import { Tooltip, ClickAwayListener, IconButton } from '@material-ui/core';
import PropType from "prop-types";

const HelpButton = ({text}) => {
  return <></>
    /*
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
      Temporarily disabled for phase 1
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                    PopperProps={{
                    disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    title={text}
                >
                <IconButton onClick={handleTooltipOpen}>
                    <InfoOutlined/>
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    )
    */
  }


export default HelpButton;

HelpButton.propTypes = {
  text: PropType.string.isRequired
}