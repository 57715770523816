import {Grid, IconButton} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FieldArray, formValueSelector} from "redux-form";
import {
  BaseSection,
  EducationLevel,
  fromId,
  isYes,
  PriorEducationalAchievementIdentifier,
  QualificationLevel,
  toId,
  toYesNo,
  Typography,
  Year,
  YesNo
} from "up-form";

const Qualifications = ({section, form, change}) => {
  const {t} = useTranslation();
  const prefix = "Qualifications";
  const selector = formValueSelector(form);

  const {completedQualifications = []} = useSelector((state) => selector(state, section) || {});

  const dateOfBirth = useSelector((state) => selector(state, "signup.dateOfBirth"));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!completedQualifications || completedQualifications.length === 0)
      dispatch(change(`${section}.completedQualifications`, [{}]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // omit deps so we suggest one on first render but can be deleted

  const renderSubFields = (achievement, index, fields) => (
    <Grid container item key={index} spacing={2} direction="row" alignItems="center">
      <Grid item xs={6}>
        <EducationLevel
          name={`${achievement}.completedQualification`}
          label={t(`${prefix}.completedQualification.label`)}
          required={index > 0}
        />
      </Grid>
      <Grid item xs={5}>
        <PriorEducationalAchievementIdentifier
          name={`${achievement}.achievementIdentifier`}
          label={t(`${prefix}.achievementIdentifier.label`)}
          required={index > 0}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton color="primary" data-testid={`${section}-remove-achievement-${index}`} onClick={() => fields.remove(index)}>
          <RemoveCircle fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
  const renderAchievements = ({fields}) => (
    <>
      <Grid container spacing={2} direction="column">
        {fields && fields.map(renderSubFields)}
        <Grid item>
          <IconButton color="primary" data-testid={`${section}-add-achievement`} onClick={() => fields && fields.push({})}>
            <AddCircle fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12}>
        <Typography variant="caption">{t(`${prefix}.completedQualifications.label`)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldArray name="completedQualifications" component={renderAchievements} />
      </Grid>
      <Grid item xs={6}>
        <QualificationLevel name="highestQualificationLevel" label={t(`${prefix}.highestQualificationLevel.label`)} />
      </Grid>
      <Grid item xs={6}>
        <Year
          fullWidth
          first={dateOfBirth && moment(dateOfBirth).year()}
          name="finalYearOfHighestQualification"
          label={t(`${prefix}.finalYearOfHighestQualification.label`)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <YesNo label={t(`${prefix}.isRplStudent.label`)} name="isRplStudent" helperText={t(`${prefix}.isRplStudent.help`)} />
      </Grid>
    </BaseSection>
  );
};

Qualifications.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Qualifications;

export function mapToApplication({
  completedQualifications,
  highestQualificationLevel,
  finalYearOfHighestQualification,
  isRplStudent
}) {
  return {
    education: {
      completedQualifications: (completedQualifications || [])
        .filter(({completedQualification, achievementIdentifier}) => !!completedQualification && !!achievementIdentifier)
        .map(({completedQualification, achievementIdentifier}) => ({
          qualificationId: toId(completedQualification),
          priorEducationalAchievementId: toId(achievementIdentifier)
        })),
      highestQualificationCompletedOrAttemptedId: toId(highestQualificationLevel),
      isRplStudent: isYes(isRplStudent),
      finalYearOfHighestQualification: finalYearOfHighestQualification
    }
  };
}

export function mapFromApplication(
  {
    education: {
      completedQualifications,
      highestQualificationCompletedOrAttemptedId,
      isRplStudent,
      finalYearOfHighestQualification
    } = {}
  },
  {metadata: {educationLevels, priorEducationalAchievementIdentifiers, qualificationLevels}}
) {
  return {
    completedQualifications: (completedQualifications || []).map(({qualificationId, priorEducationalAchievementId}) => ({
      completedQualification: fromId(educationLevels, qualificationId),
      achievementIdentifier: fromId(priorEducationalAchievementIdentifiers, priorEducationalAchievementId)
    })),
    highestQualificationLevel: fromId(qualificationLevels, highestQualificationCompletedOrAttemptedId),
    isRplStudent: toYesNo(isRplStudent),
    finalYearOfHighestQualification: finalYearOfHighestQualification
  };
}
