import * as moment from "moment";
/**
 * Business related logic that is better to not embed in forms
 */

/**
 * Calculate whether a course will be 'Fees Free'
 * @param {*} dateOfBirth
 * @param {*} course
 * @param {*} firstTertiary
 */
export function isFeesFree(dateOfBirth, course, firstTertiary) {
  return firstTertiary === "yes" && isMinor(dateOfBirth) && course;
}

/**
 * Calculate whether a student loan might be available
 */
export function isStudentLoanAvailable(course, citizenshipNZ, resident) {
  return [citizenshipNZ, resident].includes("yes") && course; /*is what ?? */
}

/**
 * Given a person's name string split it as { firstName,lastName } discarding middle initials etc
 * @param {*} name
 */
export function toFirstAndLastNames(name) {
  return !!name ? name.match(/\s*(?<firstName>\S+)?.*?(\s(?<lastName>\S+))?\s*$/).groups : {};
}

/**
 * Given date of birth, return true if person is a minor
 * @param {*} name
 */
export function isMinor(dateOfBirth) {
  return dateOfBirth && moment().diff(dateOfBirth, "years") < 18;
}

export function isNCEA2orAbove(course) {
  return course.level && course.level > 2;
}
