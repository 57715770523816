/**
 * Checkout step
 */
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Box
} from "@material-ui/core";
import {Payment} from "@material-ui/icons";
import {Alert, AlertTitle} from "@material-ui/lab";
import {startCase} from "lodash";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  BaseSection,
  Currency,
  findCurrency,
  Message,
  toCourseIntake,
  Typography,
  useGlobal,
  useSkeleton
} from "up-form";
import {createOrder, useApplication, useCourses} from "up-state";
import PayLater from "../payment/PayLater";
import Stripe from "../payment/Stripe";
const cellStyles = makeStyles((theme) => ({
  root: {
    padding: ".25em",
    borderBottom: "none"
  },
  head: {
    fontWeight: "bold"
  }
}));

const Checkout = ({section, form, change}) => {
  const {t} = useTranslation();
  const {providerSlug} = useGlobal();
  const {data: courses} = useCourses(providerSlug);
  const dispatch = useDispatch();

  // Get current invoice
  const {
    data: {
      invoiceNumber: newInvoice,
      totalAmount: newTotalAmount,
      transactionCurrency: newTransactionCurrency
    } = {},
    pending: orderPending,
    error: orderError
  } = useSelector((state) => state.createOrder || {});
  const isSkeleton = useSkeleton();
  const {
    pending: pendingApplication,
    data: {
      opportunity: {opportunityCRMId, intakeCRMId, isCompanyApplication},
      payment: {
        firstPaymentTransactionId,
        invoiceId: existingInvoice,
        totalAmount: existingTotalAmount,
        transactionCurrency: existingTransactionCurrency
      } = {}
    } = {}
  } = useApplication();
  const {course: {marketingName} = {}} =
    (courses && intakeCRMId && toCourseIntake({intakeCRMId}, courses)) || {};
  const pending = orderPending;
  const prefix = "Checkout";
  const invoiceNumber = existingInvoice || newInvoice;
  const totalAmount = existingTotalAmount || newTotalAmount;
  const hasZeroAmount = !(totalAmount > 0);
  const currencyName = existingTransactionCurrency || newTransactionCurrency;
  const currencyCode =
    currencyName &&
    (
      findCurrency({
        currencyName
      }) || {code: "NZD"}
    ).code;
  useEffect(() => {
    // Create a new invoice/order if none exists and no payment made
    if (
      !isSkeleton &&
      opportunityCRMId &&
      !(
        firstPaymentTransactionId ||
        invoiceNumber ||
        orderPending ||
        orderError
      )
    ) {
      dispatch(createOrder(opportunityCRMId));
    }
  }, [
    firstPaymentTransactionId,
    opportunityCRMId,
    invoiceNumber,
    orderPending,
    orderError,
    dispatch,
    isSkeleton
  ]);

  useEffect(() => {
    // Store possibly updated invoicenumber as a hidden field for use in mapping (oddly, application in redux store may not have been updated
    // when mapping called due callback ordering
    if (invoiceNumber) {
      change(`${section}.invoiceNumber`, invoiceNumber);
    }
  }, [invoiceNumber, change, section]);

  function RenderCurrency({value, ...other}) {
    return (
      <Currency
        component="span"
        formatOptions={{signDisplay: "auto"}}
        currency={currencyCode}
        value={Number(value)}
        {...other}
      />
    );
  }

  function SummaryLine({i18nKey, children}) {
    const classes = cellStyles();

    return (
      <TableRow>
        <TableCell variant="head" classes={classes}>
          {t(`${prefix}.paymentSummary.${i18nKey}`)}:
        </TableCell>
        <TableCell classes={classes} style={{textAlign: "right"}}>
          {children}
        </TableCell>
      </TableRow>
    );
  }
  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      {invoiceNumber && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="info" icon={<Payment />}>
              <AlertTitle>{t(`${prefix}.paymentSummary.title`)}</AlertTitle>
              {!hasZeroAmount ? (
                <Table>
                  <TableBody>
                    <SummaryLine i18nKey="totalAmount">
                      <RenderCurrency value={totalAmount} />
                    </SummaryLine>
                  </TableBody>
                </Table>
              ) : (
                <Typography variant="h3">
                  {t(`${prefix}.paymentSummary.zeroAmount`)}
                </Typography>
              )}
            </Alert>
          </Grid>
          {totalAmount > 0 && (
            <Grid item xs={12}>
              {isCompanyApplication ? (
                !firstPaymentTransactionId && (
                  <PayLater
                    i18nPrefix={`${prefix}.payLater`}
                    prefix="payLater"
                  />
                )
              ) : 
                pendingApplication ? 

                (<Box padding={5}>
                <CircularProgress variant="indeterminate" />
                <Typography variant="body1">Loading...</Typography>
                </Box>)
                :   
                  <Stripe
                  disabled={!!firstPaymentTransactionId}
                  name="stripeUrl"
                  amount={totalAmount}
                  currency={currencyCode}
                  description={marketingName}
                  invoiceNumber={invoiceNumber}
                  required
                  />
                }
            </Grid>
          )}
          {firstPaymentTransactionId && (
            <Grid item xs={12}>
              <Alert severity="success">{t(`${prefix}.thankYou`)}</Alert>
            </Grid>
          )}
        </Grid>
      )}
      <Message open={pending} variant="busy" message={t(`${prefix}.busy`)} />
    </BaseSection>
  );
};

Checkout.propTypes = {};

export default Checkout;

// When user wants to pay later we add a task note to the opportunity
export function mapToTask({payLater, invoiceNumber}) {
  return (
    payLater && {
      title: `Compmay Applicant Request for Invoice : ${invoiceNumber}`,
      description: Object.entries(payLater)
        .map(([k, v]) => `${startCase(k.split(/(?=[A-Z])/).join(" "))}: ${v}`)
        .join("\n")
    }
  );
}
export function mapToApplication({payNowOrLaterId}) {
  return {
    payment: {
      payNowOrLaterId
    }
  };
}

export function mapFromApplication({payment: {firstPaymentTransactionId, payNowOrLaterId, invoiceId} = {}} = {}) {
  return {
    payNowOrLaterId,
    transactionId: firstPaymentTransactionId,
    invoiceNumber: invoiceId
  };
}
