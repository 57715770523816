import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {
  AddressAutoComplete,
  BaseSection,
  Country,
  fromAddress,
  isYes,
  Optional,
  TextField,
  toAddress,
  toYesNo,
  Typography,
  useGlobal,
  YesNo
} from "up-form";
import HelpButton from "../app/HelpButton";

const AddressFields = ({prefix, required = (field) => false, section, change}) => {
  const {t} = useTranslation(),
    {
      component: {
        section: {Address: {autoComplete, preferCountry} = {}}
      },
      google: {apiKey}
    } = useGlobal();

  return (
    <>
      {autoComplete && (
        <Grid item xs={12}>
          <AddressAutoComplete
            required={required}
            fullWidth
            label={t("Address.autoComplete.label")}
            preferCountry={preferCountry}
            onChange={({
              flatUnitDetails,
              streetOrLotNumber,
              buildingPropertyName,
              streetAddress,
              suburb,
              city,
              country,
              state,
              postcode
            }) => {
              change(
                `${section}.${prefix}.streetAddress`,
                [flatUnitDetails, buildingPropertyName, streetOrLotNumber, streetAddress].filter((v) => !!v).join(" ")
              );
              change(`${section}.${prefix}.suburb`, suburb);
              change(`${section}.${prefix}.city`, city);
              change(`${section}.${prefix}.country`, country);
              change(`${section}.${prefix}.state`, state);
              change(`${section}.${prefix}.postcode`, postcode);
            }}
            apiKey={apiKey}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField
          required={required("streetAddress")}
          fullWidth
          name={prefix + ".streetAddress"}
          label={t("Address.streetAddress.label")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth required={required("suburb")} name={prefix + ".suburb"} label={t("Address.suburb.label")} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth required={required("city")} name={prefix + ".city"} label={t("Address.city.label")} />
      </Grid>
      <Optional name="Address.state">
        <Grid item xs={12} sm={6}>
          <TextField fullWidth required={required("state")} name={prefix + ".state"} label={t("Address.state.label")} />
        </Grid>
      </Optional>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth required={required("postcode")} name={prefix + ".postcode"} label={t("Address.postcode.label")} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Country required={required("country")} name={prefix + ".country"} label={t("Address.country.label")} fullWidth />
      </Grid>
      <Grid item xs={12} />
    </>
  );
};

const Address = ({form, section, change}) => {
  const {t} = useTranslation();
  const selector = formValueSelector(form);
  const studyAtHomeAddress = useSelector((state) => selector(state, section + ".studyAtHomeAddress"));
  return (
    <BaseSection section={section} title={t("Address.title")}>
      <AddressFields
        prefix="home"
        required={(field) => ["streetAddress", "city", "postcode"].includes(field)}
        {...{section, change}}
      />
      <Grid item sm={11}>
        <YesNo label={t("Address.studyAtHomeAddress.label")} name="studyAtHomeAddress" />
        <HelpButton text={t("Address.studyAtHomeAddress.help")} />
      </Grid>
      {studyAtHomeAddress === "no" && (
        <>
          <Grid item sm={12}>
            <Typography variant="subtitle1">{t("Address.studyAddress.label")}</Typography>
          </Grid>
          <AddressFields prefix="study" {...{section, change}} />
        </>
      )}
    </BaseSection>
  );
};

Address.propTypes = {
  change: PropTypes.any,
  form: PropTypes.any,
  section: PropTypes.string
};

export default Address;

export function mapToApplication({home, studyAtHomeAddress, study}, {metadata}) {
  const permanentAddress = toAddress(home, metadata),
    studyAddress = isYes(studyAtHomeAddress)
      ? {isSameAsPermanent: true, ...permanentAddress}
      : {isSameAsPermanent: false, ...toAddress(study, metadata)};

  return {
    student: {
      permanentAddress,
      studyAddress
    }
  };
}

export function mapFromApplication(
  {student: {permanentAddress, studyAddress, studyAddress: {isSameAsPermanent} = {}} = {}} = {},
  {metadata}
) {
  return {
    home: fromAddress(permanentAddress, metadata),
    study: fromAddress(studyAddress, metadata),
    studyAtHomeAddress: toYesNo(isSameAsPermanent)
  };
}
