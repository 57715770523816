import {Alert} from "@material-ui/lab";
import React from "react";
import {useTranslation} from "react-i18next";
import {Redirect, useParams} from "react-router-dom";
import {useQuery} from "up-form";
import {ResumeEnrol} from "./ResumeEnrol";

/**
 * Resume after payment complete redirect from payment provider (e.g. stripe)
 * @param paymentSessionId param contains an id created for completion
 */
const ResumeAferPaymentComplete = ({...other}) => {
  const {stripeSessionId: firstPaymentTransactionId} = useQuery(),
    {sessionId} = useParams(), // not to be confused with our one
    {t} = useTranslation();
  return (
    <>
      {firstPaymentTransactionId ? (
        <ResumeEnrol
          sessionId={sessionId}
          resumeStep="checkout"
          applicationUpdate={{
            payment: {
              firstPaymentTransactionId
            }
          }}
          {...other}
        >
          <Alert severity="success">
            {t("ResumeEnrol.paymentComplete.text")}
          </Alert>
        </ResumeEnrol>
      ) : (
        <Redirect
          to={`/error/400?i18nKey=ResumeEnrol.paymentComplete.noCompletionId`}
        />
      )}
    </>
  );
};

export default ResumeAferPaymentComplete;
