import {Grid} from "@material-ui/core";
import PropType from "prop-types";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {
	BaseSection,
	Country,
	Ethnicity,
	FeesAssistanceStatus,
	fromId,
	isYes,
	Iwi,
	toId,
	toYesNo,
	YesNo
} from "up-form";
import {useMetadata} from "up-state";
import FieldSet from "../form/FieldSet";

const ResidencyStatusNZ = ({form, section, change}) => {
	const {t} = useTranslation();
	const selector = formValueSelector(form);
	const nzCitizen = useSelector((state) =>
		selector(state, section + ".nzCitizen")
	);
	const ausResident = useSelector((state) =>
		selector(state, section + ".ausResident")
	);
	const nzResident = useSelector((state) =>
		selector(state, section + ".nzResident")
	);
	const ethnicity = useSelector((state) =>
		selector(state, section + ".ethnicity")
	);
	const iwis = useSelector((state) =>
		[1, 2, 3].map((n) => selector(state, section + `.iwi${n}`))
	);
	const {data: {feesAssistanceStatuses, countries} = {}} = useMetadata();
	const newZealand =
		countries && countries.find(({label}) => "New Zealand" === label);
	const needFeesAssistanceStatus =
		nzCitizen === "no" && ausResident === "no" && nzResident === "no";
	useEffect(() => {
		if (!needFeesAssistanceStatus && feesAssistanceStatuses) {
			// clear down unneeded field
			change(
				`${section}.feesAssistanceStatus`,
				feesAssistanceStatuses.find(({label}) => /Domestic/i.test(label))
			);
		}
		// Tidy mutually exclusive fields
		if (newZealand && nzCitizen === "yes") {
			change(`${section}.nzResident`, null);
			change(`${section}.ausResident`, null);
			change(`${section}.citizenship`, newZealand);
		} else if (nzResident === "yes") {
			change(`${section}.ausResident`, null);
		}
	}, [
		newZealand,
		needFeesAssistanceStatus,
		feesAssistanceStatuses,
		nzCitizen,
		nzResident,
		change,
		section
	]);
	return (
		<BaseSection section={section} title={t("ResidencyStatusNZ.title")}>
			<Grid item xs={12} sm={12}>
				<YesNo
					required
					label={t("ResidencyStatusNZ.nzCitizen.label")}
					name="nzCitizen"
				/>
			</Grid>
			{nzCitizen === "no" && ( // no other residency questions if yes
				<>
					<Grid item xs={12}>
						<Country
							fullWidth
							required
							label={t("ResidencyStatusNZ.citizenship.label")}
							filter={({id}) => id !== newZealand.id}
							name="citizenship"
						/>
					</Grid>
					<Grid item xs={12}>
						<YesNo
							required
							label={t("ResidencyStatusNZ.nzResident.label")}
							name="nzResident"
						/>
					</Grid>
					{nzResident === "no" && (
						<Grid item xs={12}>
							<YesNo
								required
								label={t("ResidencyStatusNZ.ausResident.label")}
								name="ausResident"
							/>
						</Grid>
					)}
					{needFeesAssistanceStatus && (
						<Grid item xs={12}>
							<FeesAssistanceStatus
								fullWidth
								label={t("ResidencyStatusNZ.feesAssistanceStatus.label")}
								name="feesAssistanceStatus"
								filter={({label}) =>
									/Exchange|Military|Diplomatic|Refugee/i.test(label)
								}
								orientation="vertical"
							/>
						</Grid>
					)}
				</>
			)}
			<Grid item xs={12}>
				<Ethnicity
					fullWidth
					label={t("ResidencyStatusNZ.ethnicity.label")}
					name="ethnicity"
				/>
			</Grid>
			{ethnicity && /New Zealand M[aā]ori/.test(ethnicity.label) && (
				<Grid item xs={12}>
					<FieldSet label={t("ResidencyStatusNZ.iwi.label")} direction="column">
						{iwis.map(
							(n, i, iwis) =>
								(i === 0 || iwis[i - 1]) && (
									<Grid item key={i}>
										<Iwi
											required={i === 0}
											name={`iwi${i + 1}`}
											fullWidth
											label={`Iwi ${i + 1}`}
										/>
									</Grid>
								)
						)}
					</FieldSet>
				</Grid>
			)}
		</BaseSection>
	);
};

export default ResidencyStatusNZ;

ResidencyStatusNZ.propTypes = {
	form: PropType.string.isRequired,
	section: PropType.string.isRequired
};

// TODO: better to look up from metadata?
const newZealandId = "7209f5c6-c6ba-e711-8130-e0071b795ca1";
const maoriId = "0203b7c9-c8ba-e711-8125-c4346bdee581";

export function mapToApplication({
	countryOfBirth,
	nzCitizen,
	citizenship,
	ausResident,
	nzResident,
	ethnicity,
	feesAssistanceStatus,
	iwi1,
	iwi2,
	iwi3
}) {
	return {
		opportunity: {
			feesAssistanceStatusId: toId(feesAssistanceStatus)
		},
		residency: {
			ethnicityId: toId(ethnicity),
			countryOfBirthId: toId(countryOfBirth),
			countryOfCitizenshipId: toId(citizenship),
			australianResident: isYes(ausResident),
			newZealandResident: isYes(nzResident),
			memberOfIwi:
				(maoriId === toId(ethnicity) &&
					[toId(iwi1), toId(iwi2), toId(iwi3)].filter((v) => !!v)) ||
				undefined
		}
	};
}
export function mapFromApplication(
	{
		opportunity: {feesAssistanceStatusId} = {},
		residency: {
			ethnicityId,
			countryOfBirthId,
			countryOfCitizenshipId,
			australianResident,
			newZealandResident,
			memberOfIwi
		} = {}
	},
	{metadata: {ethnicities, countries, feesAssistanceStatuses, iwis}}
) {
	return {
		countryOfBirth: fromId(countries, countryOfBirthId),
		nzCitizen: toYesNo(countryOfCitizenshipId === newZealandId),
		citizenship: fromId(countries, countryOfCitizenshipId),
		ausResident: toYesNo(australianResident),
		nzResident: toYesNo(newZealandResident),
		feesAssistanceStatus: fromId(
			feesAssistanceStatuses,
			feesAssistanceStatusId
		),
		ethnicity: fromId(ethnicities, ethnicityId),
		...(Array.isArray(memberOfIwi) &&
			memberOfIwi
				.filter((v) => !!v)
				.reduce((a, v, i) => {
					a[`iwi${i + 1}`] = fromId(iwis, v);
					return a;
				}, {}))
	};
}
