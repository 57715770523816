import {Button, CircularProgress, FormHelperText} from "@material-ui/core";
import PropTypes from "prop-types";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {required as isRequired} from "redux-form-validators";
import upApi from "up-api";
import {useGlobal} from "up-form";
import {Field} from "redux-form";
import {useApplication} from "up-state";
import {ReactComponent as StripeIcon} from "../../icons/stripe.svg";

function StripeButton({disabled, onChange, studentId, body, onError}) {
  const [busy, setBusy] = useState();
  const {t} = useTranslation();
  async function handlePayClick() {
    if (!busy) {
      try {
        setBusy(true);
        onError(undefined);
        const {url} = await upApi.createAdhocStripeSession(studentId, body);
        onChange(url);
      } catch (e) {
        onError(e);
      } finally {
        setBusy(false);
      }
    }
  }

  return (
    <Button
      color="secondary"
      variant="contained"
      disabled={disabled || busy}
      onClick={handlePayClick}
      style={{alignSelf: "flex-start"}}
    >
      <StripeIcon style={{height: "2rem"}} />
      {t("Checkout.stripe.label")}
      {busy && <CircularProgress size="1em" style={{marginRight: "1em"}} />}
    </Button>
  );
}

function renderField({input: {onChange}, onError, meta: {error}, ...other}) {
  return (
    <>
      <StripeButton onChange={onChange} onError={onError} {...other} />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
}

function Stripe({
  name,
  invoiceNumber,
  amount,
  currency = "NZD",
  description,
  required,
  disabled,
  ...other
}) {
  const [error, setError] = useState();
  function failed() {
    return error && error.message;
  }
  const {
    data: {
      student: {studentUPId: studentId, emailAddress} = {},
      opportunity: {sessionId} = {}
    } = {}
  } = useApplication();
  const {providerSlug: provider} = useGlobal();
  const body = emailAddress &&
    studentId && {
      callbackUrls: Object.fromEntries(
        ["approved", "cancelled", "declined"].map((action) => [
          action,
          `${origin}/${provider}/payment/${action}/${sessionId}?stripeSessionId={CHECKOUT_SESSION_ID}`
        ])
      ),
      isoCurrencyCode: currency,
      invoiceId: invoiceNumber,
      lineItems: [
        {
          name: "Course Fee",
          description,
          unitAmountCents: amount * 100,
          quantity: 1
        }
      ],
      paymentMethod: "card",
      provider,
      receiptEmail: emailAddress,
      useStudentEmail: true
    };
  return (
    body && (
      <Field
        name={name}
        validate={[
          !disabled &&
            required &&
            isRequired({message: "Payment is required to proceed"}),
          failed
        ].filter((v) => !!v)}
        component={renderField}
        body={body}
        studentId={studentId}
        {...other}
        onChange={(event, url) => {
          if (url) {
            document.location.replace(url);
          }
        }}
        onError={setError}
        disabled={disabled}
        {...other}
      />
    )
  );
}

Stripe.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.any.isRequired,
  currency: PropTypes.string,
  invoiceNumber: PropTypes.string.isRequired
};

export default Stripe;
